<template>
  <div class="nacelle-set">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="吊篮维护" name="first">
        <one-com></one-com>
      </el-tab-pane>
      <el-tab-pane label="登高车维护" name="second">
        <two-com></two-com>
      </el-tab-pane>
      <el-tab-pane label="吊车维护" name="fourth">
        <four-com></four-com>
      </el-tab-pane>
      <el-tab-pane label="机械租赁商" name="third">
        <three-com></three-com>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import { tabIndex } from '@/utils/local-cache'
  export default {

    name: 'nacelle-set',

    components: {
      OneCom: () => import('./nacelleComponents/one'),
      TwoCom: () => import('./nacelleComponents/two'),
      ThreeCom: () => import('./nacelleComponents/three'),
      FourCom: () => import('./nacelleComponents/four')
    },
    props: {
    },

    data() {
      return {
        activeName: 'first',
      }
    },

    computed: {
    },

    watch: {
    },
    created() {
      if (tabIndex.get()) this.activeName = tabIndex.get()
      else this.activeName = 'first'
    },
    beforeDestroy() {
      this.activeName = ''
      tabIndex.set('')
    },
    mounted() {
    },
    methods: {
      // 切换tabs
      handleClick() {
        tabIndex.set(this.activeName)
      }
    }
  }
</script>


<style lang="scss" scoped>

</style>
